import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {
  departmentList: AngularFireList<any>;
  departmentCodes: AngularFireList<any>;
  arrayCodes = [];
  array = [];

  constructor(private firebase: AngularFireDatabase) {
    this.departmentList = this.firebase.list('departments');
    this.departmentList.snapshotChanges().subscribe(
      list => {
        this.array = list.map(item => {
          return {
            $key: item.key,
            ...item.payload.val()
          };
        });
      });

      this.departmentCodes = this.firebase.list('departments');
      this.departmentCodes.snapshotChanges().subscribe(
        list => {
          this.arrayCodes = list.map(item => {
            return {
              $key: item.key,
              ...item.payload.val()
            };
          });
        });
   }


   getDepartmentName($key) {
    if ($key == "0") {
      return "";
    }
    else {
      const depName = _.find(this.array, (obj) => obj.$key === $key)['name'];
      //console.log(depName);
      //console.log($key);
      return depName;
    }
  }



}
