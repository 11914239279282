import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class IscrizioneService {
  subscriptionList: AngularFireList<any>;
  array = [];

  constructor(private firebase: AngularFireDatabase) {
    this.subscriptionList = this.firebase.list('subscriptions');
    this.subscriptionList.snapshotChanges().subscribe(
      list => {
        this.array = list.map(item => {
          return {
            $key: item.key,
            ...item.payload.val()
          };
        });
      });
   }


   getSubscriptionName($key) {
    if ($key === '0') {
      return '';
    }    else {
      const subName = _.find(this.array, (obj) => obj.$key === $key)['type'];
      return subName;
    }
  }

}


