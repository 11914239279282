import { Component } from '@angular/core';
import { AuthService } from './auth.service';
import {MatInputModule} from '@angular/material/input';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'app';
  constructor(public authService: AuthService) {}

}


